
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$crediblue-site-primary: mat.define-palette(mat.$indigo-palette);
$crediblue-site-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$crediblue-site-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$crediblue-site-theme: mat.define-light-theme((
  color: (
    primary: $crediblue-site-primary,
    accent: $crediblue-site-accent,
    warn: $crediblue-site-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($crediblue-site-theme);

/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "./assets/styles/buttons.scss";
@import "./assets/styles/html.scss";
@import "./assets/styles/variables.css";
@import "./assets/styles/responsive.scss";
@import "./assets/styles/block-ui.scss";

/*==================== Default Container =========================*/

$screen-xs: 520px; /*  Extra small screen / phone */
$screen-sm: 768px; /*  Small screen / tablet */
$screen-md: 992px; /*  Medium screen / desktop */
$screen-lg: 1280px; /*  Large screen / wide desktop */
$screen-xlg: 1920px; /* Extra Large screen / wide desktop */

html, body {
  height: 100%;
}

::ng-deep body {
  margin: 0;
  padding: 0;
  background-color: white;
}

label {
  margin-bottom: 0;
}

.custom-link {
  text-decoration: none;
  font-weight: 700;
  color: #002561;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    transition: opacity .2s;
    opacity: .5;
  }
}

.card {
  height: 380px;
  width: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content-title {
    height: 200px;
    justify-self: center;
  }

  &__details {
    height: 351px;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

div.blog-post-content p, div.blog-post-content a, div.blog-post-content strong {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  font-family: var(--lato-font);
  font-weight: 300;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  line-height: 2.25rem;
  margin-bottom: 32px;
  color: var(--grey-1000);
}

div.blog-post-content strong {
  font-weight: 800;
}

div.blog-post-content a {
  cursor: pointer;
}

div.blog-post-content h2 {
  margin: 32px 0 24px 0;
  font-family: var(--lato-font);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--grey-1000);
}

div.blog-post-content li {
  margin: 0 40px;
  font-weight: 400;
  font-size: 20px;
  color: var(--grey-1000);
  line-height: 35px;
  word-break: initial;
}

div.blog-post-content blockquote {
  border: 1px solid rgba(120,130,140,.13);
  border-left: 5px solid var(--purple-400);
  padding: 15px;
  margin: 20px 0;
}
