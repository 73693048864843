.flex-responsive {
    @media screen and (max-width: 760px) {
        .f-50 {
            flex: none;
        }
    }
}

@media screen and (max-width: 1020px) {
    .gs-flex-column {
        flex-direction: column;
    }
    .gs-f-50 {
        flex: 1;
    }
    div.gs-w-50 {
        width: 100%;
    }
}

@media screen and (max-width: 980px) {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 40px;
    }
    h3 {
        font-size: 32px;
    }
    h4 {
        font-size: 26px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 12px;
    }
    small {
        font-size: 11px;
    }
    p.default-text {
        font-size: 16px;
    }

    .grid-container {
        .md-item {
            grid-column: 1/-1;
        }
        .small-item {
            grid-column: span 2;
        }
    }
}

@media screen and (max-width: 760px) {
    h1 {
        font-size: 44px;
    }
    h2 {
        font-size: 36px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 24px;
    }
    h5 {
        font-size: 16px;
    }
    h6 {
        font-size: 11px;
    }
    p.default-text {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .grid-container {
        .small-item {
            grid-column: 1/-1;
        }
    }
    .text-align-center {
        text-align: center;
    }
    .f-res-center {
        justify-content: center;
        align-items: center;
    }
}

.ms-flex-column {
    @media screen and (max-width: 940px) {
        flex-direction: column;
    }
}

.sm-f-center {
    @media screen and (max-width: 600px) {
        align-items: center;
        justify-content: center;
    }
}

.ms-f-50 {
    @media screen and (max-width: 600px) {
        flex: 0.4;
    }
}

.ms-f-50-p {
    @media screen and (max-width: 600px) {
        flex: 0.6;
    }
}
