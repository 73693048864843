.expandable {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.expandable[expanded-area='true'] {
  grid-template-rows: 1fr;
  transition: grid-template-rows 500ms;
}

.expandable-inner {
  overflow: hidden;
  width: 100%;
}
