* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1 {
  font-family: var(--prompt-font);
  font-weight: 600;
  font-size: 56px;
}

h2 {
  font-family: var(--prompt-font);
  font-weight: 600;
  font-size: 48px;
}

h3 {
  font-family: var(--lato-font);
  font-weight: 600;
  font-size: 40px;
}

h4 {
  font-family: var(--lato-font);
  font-weight: 500;
  font-size: 32px;
}

h5 {
  font-family: var(--lato-font);
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
}

h6 {
  font-family: var(--lato-font);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
}

img {
  width: 100%;
}

.credi-container {
  min-width: 1220px;
  max-width: 1220px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    min-width: 1000px;
    max-width: 1100px;
  }

  @media screen and (max-width: 1160px) {
    min-width: 930px;
    max-width: 980px;
  }

  @media screen and (max-width: 1020px) {
    min-width: 85vw;
    max-width: 90vw;
  }

  @media screen and (max-width: 760px) {
    min-width: 85vw;
    max-width: 92vw;
  }

  @media screen and (max-width: 400px) {
    min-width: 85vw;
    max-width: 89vw;
  }
}

.credi-center {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.credi-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

small {
  font-family: var(--lato-font);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.font-w-400 {
  font-weight: 300;
}

p.disclaimer {
  font-family: var(--lato-font);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 16px;
}

p.rights {
  font-family: var(--lato-font);
  font-weight: 500;
  font-size: 10px;
}

p.default-text {
  font-family: var(--lato-font);
  font-weight: 400;
  font-size: 18px;
  text-align: justify;
}

p.align-left {
  text-align: left;
}

h1.align-right, h5.align-right, p.align-right {
  text-align: right;
}

p.blue-1000, h1.blue-1000, h5.blue-1000, h2.blue-1000 {
  color: var(--blue-1000);
}

p.blue-900, h1.blue-900, h5.blue-900 {
  color: var(--blue-900);
}

p.blue-700, h1.blue-700, h2.blue-700, h4.blue-700, h6.blue-700 {
  color: var(--blue-700);
}

p.blue-600, h1.blue-600, h2.blue-600 {
  color: var(--blue-600);
}

p.blue-100, h1.blue-100, h3.blue-100 {
  color: var(--blue-100);
}

p.purple-700 {
  color: var(--purple-700);
}

p.purple-400, h1.purple-400, h2.purple-400, h3.purple-400, h4.purple-400, h5.purple-400, h6.purple-400, small.purple-400 {
  color: var(--purple-400);
}

p.grey-500, h4.grey-500, h6.grey-500, a.grey-500 {
  color: var(--grey-500);
}

p.white-400, h1.white-400, h2.white-400, h3.white-400,
p.white, h3.white, h4.white, label.white, small.white, li.white {
  color: white;
}

h3.white, p.white, h4.white, label.white, small.white, h2.white {
  color: white;
}

h4.font-w-600 {
  font-weight: 600;
}

li {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--lato-font);
}

.grid-container {
  display: grid;

  &.grid-2-c {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid-4-c {
    grid-template-columns: repeat(4, 1fr);
  }
}

.big-item {
  grid-column: 1/-1;
}

.md-item {
  grid-column: span 2;
}

.small-item {
  grid-column: span 1;
}

.flex-item {
  display: flex;
  flex-wrap: wrap;
}

.display-flex {
  display: flex;
}

.f-10 {
  flex: 0.1;
}

.f-04 {
  flex: 0.04;
}

.f-25 {
  flex: 0.25;
}

.f-30 {
  flex: 0.3;
}

.f-40 {
  flex: 0.4;
}

.f-50 {
  flex: 0.5;
}

.f-60 {
  flex: 0.6;
}

.f-70 {
  flex: 0.7;
}

.f-75 {
  flex: 0.75;
}


.f-90 {
  flex: 0.9;
}


.f-96 {
  flex: 0.96;
}

.f-column {
  flex-direction: column;
}

.f-row-reverse {
  flex-direction: row-reverse;
}

.f-center {
  align-items: center;
  justify-content: center;
}

.f-align-top {
  align-items: flex-start;
}

.f-align-left {
  align-items: flex-start;
}

.f-align-right {
  align-items: flex-end;
}

.f-justify-left {
  justify-content: left;
}

.f-justify-right {
  justify-content: right;
}

.f-justify-space-between {
  justify-content: space-between;
}

.f-justify-start {
  justify-content: flex-start;
}

.f-self-center {
  align-self: center;
  justify-self: center;
}

.f-self-bottom {
  align-self: flex-end;
}

.f-self-right {
  justify-self: flex-end;
}

.f-gap-12 {
  gap: 12px;
}

.f-gap-16 {
  gap: 16px;
}

.f-gap-24 {
  gap: 24px;
}

.f-gap-32 {
  gap: 32px;
}

.f-gap-48 {
  gap: 48px;
}

.f-gap-68 {
  gap: 68px;
}

.f-gap-140 {
  gap: 140px;
}

section.grey-600, div.grey-600 {
  background-color: var(--grey-600);
}

section.grey-600-o, div.grey-600-o {
  background-color: rgba($color: #e6e6e6, $alpha: 0.3);
}

section.grey-200, div.grey-200 {
  background-color: var(--grey-200);
}

section.blue-1000, div.blue-1000 {
  background-color: var(--blue-1000);
}

section.blue-700, div.blue-700 {
  background-color: var(--blue-700);
}

section.blue-600, div.blue-600 {
  background-color: var(--blue-600);
}

section.blue-100, div.blue-100 {
  background-color: var(--blue-100);
}

section.purple-400, div.purple-400 {
  background-color: var(--purple-400);
}

section.white, div.white {
  background-color: white;
}

.m-top-16 {
  margin-top: 16px;
}

.m-top-32 {
  margin-top: 32px;
}

.m-top-36 {
  margin-top: 36px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-top-64 {
  margin-top: 64px;
}

.m-top-72 {
  margin-top: 72px;
}

.m-top-96 {
  margin-top: 96px;
}

.m-top-108 {
  margin-top: 108px;
}

.m-top-120 {
  margin-top: 120px;
}

.m-top-150 {
  margin-top: 150px;
}

.m-bottom-16 {
  margin-bottom: 16px;
}

.m-bottom-24 {
  margin-bottom: 24px;
}

.m-bottom-36 {
  margin-bottom: 36px;
}

.m-bottom-50 {
  margin-bottom: 50px;
}

.m-bottom-64 {
  margin-bottom: 64px;
}

.m-bottom-84 {
  margin-bottom: 84px;
}

.m-bottom-120 {
  margin-bottom: 120px;
}

.m-bottom-132 {
  margin-bottom: 132px;
}

.m-left-12 {
  margin-left: 12px;
}

.m-left-132 {
  margin-left: 132px;
}

.m-left-18 {
  margin-left: 18px;
}

.m-left-700 {
  margin-left: 700px;
}

.m-right-12 {
  margin-right: 12px;
}

.m-right-24 {
  margin-right: 24px;
}

.m-right-30 {
  margin-right: 30px;
}

.m-right-50 {
  margin-right: 50px;
}

.m-vertical-120 {
  margin: 120px 0;
}

.p-top-40 {
  padding-top: 40px;
}

.p-top-120 {
  padding-top: 120px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-vertical-horizontal-50 {
  padding: 50px 0;
}

.p-vertical-24 {
  padding: 24px 0;
}

.p-vertical-108 {
  padding: 108px 0;
}

.p-vertical-120 {
  padding: 120px 0;
}

.p-vertical-40-horizontal-32 {
  padding: 40px 32px;
}

.p-vertical-60-horizontal-54 {
  padding: 60px 54px;
}

.p-vertical-20-horizontal-20 {
  padding: 20px 20px;
}

.p-vertical-64-horizontal-0 {
  padding: 64px 0;
}

.p-vertical-172-horizontal-108 {
  padding: 172px 108px;
}

.p-horizontal-108 {
  padding: 0 108px;
}

.p-top-100-bottom-200 {
  padding-top: 100px;
  padding-bottom: 200px;
}

.p-top-15-bottom-15 {
  padding-top: 15%;
  padding-bottom: 15%;
}

p.text-shadow-blue, h3.text-shadow-blue, li.text-shadow-blue {
  text-shadow: -1px 4px 8px rgba(153, 214, 234, 0.25);
}

.b-purple-400 {
  border: 1px solid var(--purple-400);
}

.b-radius-8 {
  border-radius: 8px;
}

.b-radius-16 {
  border-radius: 16px;
}

.b-top-radius-3px {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.b-bottom-radius-3px {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
}

.bg-image-home {
  background-position: initial;
  background-repeat: no-repeat;

  @media screen and (max-width: 760px) {
    background-position: center;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

main {
  margin-top: 88px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.planning {
    background-image: linear-gradient(to left, rgba(245, 246, 252, 0.0), rgba(255, 255, 255, 1)),
    url("./../../assets/imgs/planning-bg.webp");
  }

  &.office {
    background-image:
      linear-gradient(to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)),
    url("./../../assets/imgs/office.webp");


    @media screen and (max-width: 550px) {
      background-image: none;
    }
  }

  &.careers {
    background-image:
      linear-gradient(to left,
        rgb(255, 255, 255),
        rgb(255, 255, 255),
        rgb(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)),
      linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)),
    url("./../../assets/imgs/careers.webp");

    @media screen and (max-width: 820px) {
      background-position: 25%;
    }
    @media screen and (max-width: 500px) {
      background-image: none;
    }
  }
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-top-right {
  top: 0;
  right: 0;
}

.pos-bottom-left {
  bottom: -100px;
  left: -100px;
}

.pos-bottom-bellow {
  bottom: -36px;
}

.pos-horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

div.h-600 {
  height: 600px;
}

div.w-15 {
  width: 15%;
}

div.w-20 {
  width: 20%;
}

div.w-33 {
  width: calc(100% / 3);
}

div.w-25 {
  width: calc(100% / 4);
}

div.w-33-m-48 {
  width: calc(100% / 3 - 48px);
}

div.w-50 {
  width: 50%;
}

div.w-60 {
  width: 60%;
}

div.w-100 {
  width: 100%;
}

div.w-300 {
  width: 300%;
}

div.w-400 {
  width: 400%;
}

div.h-10 {
  height: 10%;
}

div.h-20 {
  height: 20%;
}

div.h-70 {
  height: 70%;
}

div.h-80 {
  height: 80%;
}

div.h-100 {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}


.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

input::placeholder {
  font-family: var(--lato-font);
  color: var(--grey-400);
}
