:root {

    /* Blue Colors Pallet */
    --blue-1000: #001871;
    --blue-900: #07006C;
    --blue-700: #0072CE;
    --blue-600: #139ECB;
    --blue-100: #99D6EA;

    /* Purple Colors Pallet */
    --purple-700: #49005E;
    --purple-400: #731FB5;
    --purple-100: #D397E5;

    /* Yellow Colors Pallet */
    --yellow-1000: #FBD872;

    /* Grey Colors Pallet */
    --grey-1000: #383838;
    --grey-600: #E6E6E6;
    --grey-500: #747880;
    --grey-400: #BFC0C4;
    --grey-300: #D6D7DA;
    --grey-200: #EFF0F1;

    /* Red Colors */
    --red-100: #FF5252;

    /* Project Fonts */
    --lato-font: 'Lato', sans-serif;
    --prompt-font: 'Prompt', sans-serif;

    /* Paddings */
    --section-padding: 108px;

    /* Neutral Colors */
    --neutral-darkest: #000000;
    --neutral-light: #f3f3f3;
    --neutral-lightest: #f6f6f6;

}
