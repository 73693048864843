a.default {
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    font-family: var(--lato-font);
    text-decoration: none;
}

a.default.active-link {
  font-weight: 700;
}

a.default:hover {
    color: var(--yellow-1000);
    text-decoration: underline;
    transition: 0.2s;
}

button, a, button:hover, a:hover {
    transition: 0.2s;
}

button.flat, a.flat {
    text-decoration: none;
    padding: 10px 4px;
    border: 0;
    outline: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Lato';
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 12px;
    &.animated {
      position: relative;
      &:hover{
        padding-right: 40px;
        &::after {
          position: absolute;
          top: 8px;
          right: 8px;
          content: url('./../../assets/imgs/arrow-right-white.svg');

          @media screen and (max-width: 500px) {
            top: 4px;
            right: 4px;
          }
        }
      }
    }
}

button.blue-1000 {
    background-color: var(--blue-1000);
    color: white;
    &:not(:disabled):hover {
      background-color: var(--purple-400);
      color: white;
    }
}

button.blue-600, a.blue-600 {
    background-color: var(--blue-600);
    color: white;
    &:hover {
      background-color: var(--purple-400);
      color: white;
    }
}

a.blue-700 {
    background-color: var(--blue-700);
    color: white;
    &:hover {
      background-color: var(--blue-1000);
      color: white;
    }
}

button.purple-400, a.purple-400 {
  background-color: var(--purple-400);
  color: white;
  &:hover {
    background-color: var(--purple-100);
    color: white;
  }
}

button.purple-400-s, a.purple-400-s {
  border: 1px solid var(--purple-400);
  background-color: white;
  color: var(--purple-400);
  &:hover {
    background-color: var(--purple-400);
    color: white;
  }
}

button.purple-border {
  border: 1px solid var(--purple-400);
}

a.rounded {
    width: 42px;
    height: 42px;
    border-radius: 84px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

a.text {
    background-color: inherit;
    border: 0;
    outline: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: var(--lato-font);
    text-decoration: underline;
    &:hover {
      color: var(--blue-1000);
    }
}

button.white, a.white {
    color: white;
}

button.radio {
    width: 280px;
    background-color: white;
    border: 0;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-500);
    padding-bottom: 12px;
    border-bottom: 1px solid black;
    font-weight: 700;
    font-size: 14px;
    font-family: var(--lato-font);
    &:hover {
      color: var(--purple-400)
    }
    &.active {
      color: var(--purple-400);
      border-bottom: 3px solid var(--purple-400);
    }
}

button.h-41 {
  height: 41px;
}

button.shadow-text {
  background-color: inherit;
  border: 0;
  font-family: var(--lato-font);
  font-weight: 700;
  font-size: 14px;
  color: var(--blue-100);
  &:hover {
    color: var(--blue-700);
  }
  &.active {
    color: white;
  }
}

button.max-width, a.max-width {
  width: max-content;
}
